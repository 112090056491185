.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.4); /* Black with opacity */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: black;
  color: #fefefe;
  padding: 20px;
  border: 1px solid #ea580c;
  width: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.close {
  color: #fefefe;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: #aaa;
  text-decoration: none;
  cursor: pointer;
}