* {
  box-sizing: border-box; /* Include padding and borders in total width and height */
}

.app {
  text-align: center;
  position: relative;
  z-index: 2; /* Ensure .app content is above the background video */
  padding-bottom: 15vh; /* Start with a default padding */
  background-color: black;
  color: white;
}

.background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* This prevents the video from being stretched */
  z-index: -10; /* Set background video behind other content */
}

/* Global Font Size */
html {
  font-size: 16px;
}

@media (max-width: 768px) {
  html {
    font-size: 14px; /* Smaller font size on smaller screens */
  }
}

/* Header Styling */

.header {
  position: absolute;
  top: 0;
  left: 0;
  height: 60px; /* Adjust based on the size of your logo */
  width: 100%;
  display: flex;
  align-items: center;
  background-color: transparent;
  z-index: -1; /* Higher than the background video but not covering other elements */
  padding-left: 10px; /* Space from the left edge */
  order: 2;
}

.logo {
  height: 125px; /* Adjust the size of your logo */
  position: absolute; /* Position logo absolutely within the header */
  top: 5px; /* Adjust based on header height */
  left: 10px; /* Adjust for spacing from the left edge */
}

@media (max-width: 768px) {
 .header {
    display: flex;
    justify-content: center; /* Center the child elements (logo) */
    height: 60px; /* Adjust based on the size of your logo */
    padding-left: 0; /* Remove left padding for centering */
    padding-top: 60px;
    margin-top: 60px;
    margin-bottom: 50px;
  }

  .logo {
    height: auto; /* Adjust the size of your logo */
    width: 100%; /* Make logo take the full width */
    position: relative; /* Change position to relative for proper centering */
    top: 0; /* Reset top positioning */
    left: 0; /* Reset left positioning */
    padding-top: 60px;
    padding-bottom: 20px;
  }
}

/* Navigation Styling */

.navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 768px) {
  .nav-wrapper {
  margin-top: 50px;
  padding-top: 30px;
  text-align: center;
}
}

.nav-wrapper {
  margin-top: 30px;
  text-align: center;
  order: 3;
}

.nav-button {
  padding: 10px 20px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #a3a3a3;
  text-decoration: none;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  flex: 1 0 80%; /* Full width buttons on small screens */
  margin-bottom: 10px; /* Add some space between buttons */
}

.main-content {
  padding-bottom: 80px;
}

@media (min-width: 769px) {
  .nav-button {
    flex: 0 0 auto; /* Auto width buttons on larger screens */
  }
}

.nav-button:hover {
  background-color: #525252;
  color: white;
}

.nav-button.active {
  background-color: #ea580c;
  border-color: #ea580c;
  color: white;
}

/* Footer Styling */
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer a {
  display: block; /* Each link on its own line on small screens */
}

@media (min-width: 769px) {
  .footer {
    flex-direction: row;
    justify-content: space-around;
    margin-top: 200px;
  }

  .footer a {
    display: inline; /* Links inline on larger screens */
  }
}

.twitter-icon {
  width: 40px; /* Adjust size as needed */
  height: auto;
  margin-right: 20px; /* Space between icons and text */
}

.discord-icon {
  width: 200px; /* Adjust size as needed */
  height: auto;
  margin-right: 20px; /* Space between icons and text */
}

.tele-icon {
  width: 40px; /* Adjust size as needed */
  height: auto;
  margin-right: 20px; /* Space between icons and text */
}

.degen-icon {
  width: 80px; /* Adjust size as needed */
  height: auto;
  margin-right: 20px; /* Space between icons and text */
}

/* Dynamic Widget */

.top-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-right: 10px;
  z-index: 1000;
  order: 1;
}

@media (max-width: 768px) {
  .top-container {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 100px;
    z-index: 1000;
  }
}

/* Balances.js */

.balance-container {
    position: fixed;
    bottom: 15%;
    left: 20%;
    width: 60%;
    display: flex;
    justify-content: space-between;
    border: 3px solid #ea580c;
    padding: 10px;
    background-color: black;
    color: white;
}

.balance-item {
    border-right: 3px solid #ea580c;
    padding-right: 10px;
    flex: 1;
    font-size: 25px;
}

.balance-item:last-child {
    border-right: none;
}

@media (max-width: 768px) {
    .balance-container {
        position: static; /* Adjust if necessary for mobile layout */
        width: 100%; /* Full width on mobile */
        display: flex;
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items horizontally */
        padding-top: 20px; /* Adjust padding for mobile layout */
        padding-bottom: 20px;
        margin-top: 50px;
        margin-bottom: 150px;
    }

    .balance-item {
        border-right: none; /* Remove right border in mobile view */
        padding-right: 0; /* Remove right padding in mobile view */
        padding-bottom: 10px; /* Space between items */
        width: 90%; /* Adjust as per your design needs */
        text-align: center; /* Center align the text */
    }
}

/* DegenDashboard.js */

.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dashboard-section-wrapper {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-top: 2rem;
}

.dashboard-section {
    flex: 1;
    border: 3px solid #ea580c;
    padding: 10px;
    border-radius: 5px;
    background-color: black;
    color: white;
    font-size: 25px;
}

.dashboard-section h2 {
  font-size: 30px;
}

.dashboard-section h3 {
  font-size: 25px;
}

.dashboard-section div:not(:last-child) {
    margin: 20px;
}

.dashboard-section:first-child {
    margin-right: 10%;
}

.dashboard-section:last-child {
    margin-left: 10%;
}

@media (max-width: 768px) {
    .dashboard-section-wrapper {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .dashboard-section {
        width: 90%; /* Adjust as per your design needs */
        margin-bottom: 20px; /* Adds space between sections */
    }

    .dashboard-section:first-child,
    .dashboard-section:last-child {
        margin-right: 0; /* Removes specific margins for the first and last child */
        margin-left: 0;
        margin-bottom: 20;

    }
}

/* My NFT Display */

.dashboard {
  display: flex;
  flex-direction: row; /* Align items in a row */
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
}

.mynft-card {
  flex-basis: calc(25% - 20px); /* 4 cards per row on desktop */
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 10px;
  text-align: center;
}

@media (max-width: 768px) {
  .mynft-card {
    flex-basis: calc(100% - 20px); /* 1 card per row on small screens */
  }
}

.mynft-image {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}

.mynft-details {
    text-align: left;
    margin-top: 10px;
}

.mynft-details h3 {  /* Assuming 'h3' is the tag used for the name */
    text-align: center;
    margin-top: 10px; /* Adjust as needed for spacing */
}

/* Summon.js */

.summon {
  background-color: black;
}

.orbs-section {
    display: inline-block;
    border: 3px solid #ea580c;
    padding: 20px;
    border-radius: 5px;
    background-color: black;
    margin: 0 auto;
    text-align: center;
}

.orbs-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 1rem; 
}

.input-box {
    padding: 10px;
    margin: 5px;
    border: 3px solid #ea580c;
    border-radius: 5px;
    text-align: center;
    width: 100%;
}

.summon-button {
    padding: 10px 20px;
    background-color: #ea580c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 10px;
    width: 100%;
}

.insufficient-funds {
    color: #dc2626;
}

/* Redeem.js */

.redeem-container {
  display: flex;
  justify-content: space-around; /* Use space-around for even spacing on the sides and in-between */
  flex-wrap: wrap; /* Allows items to wrap in smaller viewports */
  margin: 0 auto; /* Center the container */
  max-width: 1200px; /* Adjust this as necessary for your design */
}

.redeem-section {
  flex-basis: 40%; /* Each section takes up 40% of the flex container width */
  border: 3px solid #ea580c;
  padding: 20px;
  margin: 10px; /* This provides spacing between sections */
  border-radius: 5px;
  background-color: black;
  text-align: center;
  display: flex;
  flex-direction: column; /* Align children vertically */
}

/* Redeem title styling */
.redeem-title {
  margin-bottom: 20px; /* Adds space between the title and the box */
  text-align: left; /* Aligns the title to the left */
  width: 100%; /* Full width */
  order: -1; /* Makes the title come first in the flex container */
}

.input-box {
    padding: 10px;
    margin: 5px;
    border: 3px solid #ea580c;
    border-radius: 5px;
    color: black;  /* This sets the text color inside the input to white */
    background-color: white; /* This ensures the background is black */
    caret-color: black;  /* This sets the blinking cursor color to white */
}

.green-button, .red-button {
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 10px;
}

.green-button {
    background-color: #ea580c;
}

.red-button {
    background-color: #988A70;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .redeem-container {
    display: flex;
    flex-direction: column; /* Stack the items vertically */
    align-items: center; /* Center align the items horizontally */
    justify-content: center; /* Ensures the container itself is centered */
    width: 100%; /* Take the full width */
  }

  .redeem-section {
    width: 90%; /* Adjust width for mobile view */
    margin: 10px auto; /* Center the sections with automatic margins */
    flex-basis: auto; /* Reset flex-basis for mobile to take full width of container */
    text-align: center; /* Center align the text within the section */
  }

  .redeem-inputs {
    width: 100%; /* Take full width to align the inputs in the center */
    display: flex; 
    flex-direction: column; /* Stack the inputs vertically */
    align-items: center; /* Center align the inputs */
  }

  .redeem-inputs h2 {
    width: 100%; /* Full width to center the text */
    text-align: center; /* Center align the h2 text */
  }

  .input-box {
    width: 80%; /* Adjust input box width as needed */
    margin: 10px auto; /* Center the input box */
  }
}